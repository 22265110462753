.wrapper {
}

.icon {
  color: var(--color-text-light-1);
}

.iconContainer {
  margin-right: 0.625rem;
}

.advanceFilterLabel {
  color: var(--color-text-light-1);
  font-size: var(--font-size-small-1);
}

.confirmButton {
  min-width: 5.625rem;
}

.numericButton > button {
  width: 1.5rem !important;
}

.numericButton > input {
  width: 6rem;
}
