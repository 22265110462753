@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 100;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Light.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 200;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Light.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Light.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Regular.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Medium.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Medium.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-SemiBold.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Bold.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-ExtraBold.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 100;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-LightItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 200;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-LightItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-LightItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-Italic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 500;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-MediumItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-MediumItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-SemiBoldItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-BoldItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: local('opensans'),
        url('../fonts/opensans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/opensans/OpenSans-ExtraBoldItalic.ttf') format('truetype')
}

