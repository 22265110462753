.wrapper {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;

  cursor: pointer;
  height: 2.1875rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: var(--border-radius-0);
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  text-decoration: none;
  padding-left: 0.5rem;
  color: var(--color-text);
}

.wrapper:hover {
  background-color: var(--color-bg-2);
}

.activeBackground {
  background-color: var(--color-primary);
  width: calc(100% - 0.25rem * 4);
  border-radius: var(--border-radius-0);
  height: 2.1875rem;
}

.wrapperActive {
  cursor: pointer;
  height: 2.1875rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: var(--border-radius-0);
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  text-decoration: none;
  padding-left: 0.5rem;
  color: var(--color-white);
  animation-name: change-text-color;
  animation-duration: 1.5s;
}

.wrapperActive path {
  color: var(--color-white);
  animation-name: change-text-color;
  animation-duration: 1.5s;
}

.iconContainer {
  width: 1.5625rem;
}

@keyframes change-text-color {
  0% {
    color: var(--color-text);
  }
  100% {
    color: var(--color-white);
  }
}
