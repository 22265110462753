.wrapper {
  position: absolute;
  border-radius: 0.5rem;
  max-width: 250px;
  right: 0.625rem;
  z-index: 100;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--color-text);
}

.profileContainer {
  padding-top: 10px;
}

.avatar {
  width: 100px;
  height: 100px;
}

.userName {
}

.userTitle {
  color: var(--color-text-light-2);
  font-size: var(--font-size-small-1);
}
