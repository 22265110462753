.wrapper {
  background-color: var(--color-primary-light);
  color: var(--color-text);
  font-size: var(--font-size-small-1);
  height: var(--height-nav-bar);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-big);  
}

.logoImage {
  width: 2rem;
  height: 2rem;
}