.wrapper {
  width: var(--width-side-menu);
  min-width: var(--width-side-menu);
  background-color: var(--color-bg-1);
  height: 100vh;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
  overflow: hidden;
  position: fixed;
  z-index: 90;
}

.hidden {
  display: none;
}

.header {
  height: var(--height-nav-bar);
}

/* customize scroll bar */
.wrapper:hover {
  overflow: auto;
}

.wrapper::-webkit-scrollbar {
  width: 3px;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-scroll-bar);
  border-radius: 5px;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-scroll-bar-hovered);
  width: 5px;
}

.wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

/* .menuHeader {
  background-color: chartreuse;
} */

/* .menuItem {
  background-color: cadetblue;
} */
