.wrapper {
  color: var(--color-text-light-1);
  font-size: var(--font-size-small-1);
}

.line{
  background-color: var(--color-border);
  width: 0.725rem;
  height: 2px;
}

.lineEnd{
  background-color: var(--color-border);
  flex-grow: 1;
  height: 2px;
}