.itemContainer {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  width: 110px;
  height: 140px;
}

.itemContainer:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.itemContainerSelected {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  width: 110px;
  height: 140px;
  background-color: #cce8ff;
  border: solid 1px #99d1ff;
}

.itemContainerImage {
  /* background-color: coral; */
  width: 100%;
  height: 130px;
  /* border: solid 1px; */
}

.itemContainerLabel {
  /* background-color: darkorchid; */
  width: 100%;
  height: 50px;
  color: var(--color-text);
  line-height: 1;
  text-align: center;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-small-1);
}
