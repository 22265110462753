@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: 
        url('../fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: 
        url('../fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: 
        url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: 
        url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: 
        url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: 
        url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: 
        url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: 
        url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: 
        url('../fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-Black.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: 
        url('../fonts/montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: 
        url('../fonts/montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: 
        url('../fonts/montserrat/Montserrat-LightItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: 
        url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: 
        url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: 
        url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: 
        url('../fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: 
        url('../fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype')
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: 
        url('../fonts/montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
        url('../fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype')
}
