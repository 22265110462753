.wrapper {
    cursor: pointer;
    font-size: var(--font-size-normal);
    min-height: 2.675rem;
}

.wrapper:hover{
    background-color: var(--color-bg-2);
}

.wrapper:active {
    background-color: var(--color-primary-light);
    color: var(--color-primary);
}

.iconContainer{
    /* background-color: aqua; */
    width: 30px;
}